<script>
import BasePasswordInput from '@shared/components/BasePasswordInput.vue';
import AffiliateConditionList from '@school/components/AffiliateConditionList.vue';
import { mapState } from 'vuex';

export default {
  components: { BasePasswordInput, AffiliateConditionList },
  static: {
    form: {
      firstname: {
        type: 'text',
        column: 'is-6',
        label: 'Prénom',
        inputAttrs: {
          required: true,
          maxlength: 128,
          hasCounter: false,
        },
      },
      lastname: {
        type: 'text',
        column: 'is-6',
        label: 'Nom',
        inputAttrs: {
          required: true,
          maxlength: 128,
          hasCounter: false,
        },
      },
      email: {
        type: 'email',
        column: 'is-12',
        label: 'Adresse e-mail',
        inputAttrs: {
          required: true,
        },
      },
    },
  },
  data: () => ({
    isLoading: false,
    newAffiliate: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
    },
  }),
  computed: {
    ...mapState('store', {
      store: 'data',
    }),
  },
  methods: {
    handle() {
      const newAffiliate = { ...this.newAffiliate };
      this.isLoading = true;
      this.$store.dispatch('auth/registerAffiliate', newAffiliate)
        .then(() => {
          this.$router.push({ name: 'dashboard' });
          this.$buefy.toast.open({
            type: 'is-success',
            message: 'Bienvenue !',
          });
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <section class="rapage section has-background-light is-custom">
    <div class="container is-520">
      <form
        class="box"
        @submit.prevent="dataIsValid(isLoading) && handle()">
        <h1 class="title is-4 is-size-5-touch is-custom">
          Inscription au programme d'affiliation
        </h1>

        <div class="columns is-multiline">
          <div
            v-for="(field, k) in $options.static.form"
            :key="k"
            class="column mb-0"
            :class="field.column"
          >
            <GeneratorField
              :fieldName="k"
              :fieldDefinition="field"
              :formData.sync="newAffiliate"
            />
          </div>
          <div class="column is-12">
            <b-field label="Mot de passe">
              <BasePasswordInput
                v-model="newAffiliate.password"
              />
            </b-field>
          </div>
          <div class="column is-12 has-text-right">
            <b-button
              type="is-primary is-custom"
              native-type="submit"
              :loading="isLoading"
              expanded
            >
              Envoyer
            </b-button>
          </div>
        </div>
      </form>

      <article class="box">
        <h2 class="title is-5 is-size-6-touch is-custom">
          Les conditions du programme
        </h2>
        <AffiliateConditionList class="is-size-9-mobile" />
        <div
          v-if="store.affiliation_options.description"
          class="content mt-4"
          v-html="store.affiliation_options.description"
        />
        <p class="has-text-weight-bold mt-4">
          Les conditions peuvent changer à tout moment,
          pour tous les affiliés ou pour votre compte spécifiquement.
        </p>
      </article>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.rapage {
  min-height: $school-main-min-height;
}
</style>
